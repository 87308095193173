<template>
<!-- eslint-disable -->
	<div>
		<h2 class="text-center" style="font-size: 28px!important; margin: 70px 0;">目標達成fire</h2>
	</div>

</template>
<script>
/* eslint-disable */
import * as api from '@/api.js'
export default {
	name: 'Progress',
    data () {
    	return {
   
 		}
    },

	methods: {
		load(){
		},
	},
	created() {
		// this.load()
	},
}
</script>
